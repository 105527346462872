import React from "react";
import { autobind } from "react-decoration";
import { navigate } from "gatsby";
import qs from "query-string";
import PropTypes from "prop-types";
import client from "lib/ApolloClient";
import gql from "graphql-tag";
import { ApolloProvider, Query } from "react-apollo";
import { inject, observer } from "mobx-react";
import { withI18next } from "lib/withI18next";
import searchAPI from "lib/searchAPI";
import KeyboardInput from "components/form/KeyboardInput";
import withLocation from "lib/withLocation";
import Link from "lib/Link";

const getResourceAutoComplete = gql`
  query getResourceAutoComplete($form: AutoCompleteForm) {
    getResourceAutoComplete(Input: $form) {
      list
    }
  }
`;

const getResourceRelatedField = gql`
  query getResourceRelatedField {
    getResourceRelatedField {
      dbFieldList
      journalFieldList
    }
  }
`;

const setResourceSearchHistory = gql`
  mutation setResourceSearchHistory($form: SearchHistoryForm) {
    result: setResourceSearchHistory(Input: $form) {
      success
      message
      errorType
    }
  }
`;

const getResourceSearchHistory = gql`
  query getResourceSearchHistory {
    getResourceSearchHistory {
      rshItemList {
        searchType
        searchValue
        searchField
        op
        searchTime
      }
    }
  }
`;

class PromptWord extends React.Component {
  render() {
    let { q, resourceType } = this.props;
    if (q == null || q.trim() == "") {
      return null;
    }

    return (
      <ApolloProvider client={client.jumperrwdClient}>
        <Query
          query={getResourceAutoComplete}
          variables={{ form: { resourceType: resourceType, inputText: q } }}
        >
          {({ loading, data, error }) => {
            if (error) return "";
            if (!loading && data !== null) {
              const { list } = data.getResourceAutoComplete;
              if (list == null || list.length == 0) {
                return null;
              }
              return (
                <>
                  <ul className="promptword">
                    {list.map((word, key) => (
                      <li key={`word${key}`}>
                        <a
                          tabIndex="0"
                          onClick={this.props.autoComplete.bind(this, word)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              this.props.autoComplete(word, e);
                            }
                          }}>
                          {word}
                        </a>
                      </li>
                    ))}
                  </ul>
                </>
              );
            } else {
              return null;
            }
          }}
        </Query>
      </ApolloProvider>
    );
  }
}

PromptWord.propTypes = {
  q: PropTypes.string,
};

class RecentSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: props.readerStore.auth,
      refetch: null,
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.auth !== props.readerStore.auth) {
      if (this.state.refetch !== null) {
        this.state.refetch();
      }
      this.setState({ auth: props.readerStore.auth });
    }
  }

  render() {
    let { t } = this.props;
    return (
      <ApolloProvider client={client.jumperrwdClient}>
        <Query query={getResourceSearchHistory} fetchPolicy="network-only">
          {({ loading, data, refetch, error }) => {
            if (error) return "";
            if (this.state.refetch === null) {
              this.setState({ refetch: refetch });
            }
            if (!loading && data && data.getResourceSearchHistory) {
              let { rshItemList } = data.getResourceSearchHistory;
              if (
                rshItemList === undefined ||
                rshItemList === null ||
                rshItemList.length === 0
              ) {
                return null;
              }
              return (
                <>
                  <div className="title">
                    {t("jumperrwd.common.searchRecord")}
                  </div>
                  <ul className="record_list">
                    {rshItemList.map((rshItem) => {
                      let searchType = "";
                      let searchCondition = "";
                      let searchTime = rshItem.searchTime;
                      if (rshItem.searchType === "databases") {
                        searchType = t("jumper.common.leftmenu.databases");
                      } else if (rshItem.searchType === "ejournal") {
                        searchType = t("jumper.common.leftmenu.ejournals");
                      } else if (rshItem.searchType === "pjournal") {
                        searchType = t("jumper.common.leftmenu.pjournals");
                      } else if (rshItem.searchType === "ebook") {
                        searchType = t("jumper.common.leftmenu.ebooks");
                      } else if (rshItem.searchType === "") {
                        searchType = t("jumper.common.all");
                      }
                      rshItem.searchValue.map((value, key) => {
                        if (value !== "NULL") {
                          let searchField = "";
                          let op = "";
                          if (
                            rshItem.searchField &&
                            rshItem.searchField.length > key
                          ) {
                            if (rshItem.searchField[key] === "all") {
                              searchField = t("jumper.common.all");
                            } else if (rshItem.searchType === "databases") {
                              searchField = t(
                                "jumperrwd.eb_basic_extension.name." +
                                  rshItem.searchField[key]
                              );
                            } else {
                              searchField = t(
                                "jumperrwd.eb_journal_extension.name." +
                                  rshItem.searchField[key]
                              );
                            }
                            if (searchCondition !== "") {
                              op = rshItem.op[key - 1].toUpperCase();
                            }
                          } else {
                            searchField = t("jumper.common.all");
                          }
                          searchCondition += t(
                            "jumperrwd.search.resourceRecentSearchCondition",
                            {
                              op: op !== "" ? " " + op + " " : "",
                              searchField: searchField,
                              searchValue: value,
                            }
                          );
                        }
                        return "";
                      });
                      return (
                        <>
                          <li>
                            <a
                              tabIndex="0"
                              onClick={this.props.setRecentSearch.bind(
                                this,
                                rshItem
                              )}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  this.props.setRecentSearch.bind(
                                    this,
                                    rshItem
                                  );
                                }
                              }}>
                              {t("jumperrwd.search.resourceRecentSearch", {
                                searchType,
                                searchCondition,
                                searchTime,
                              })}
                            </a>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </>
              );
            } else {
              return null;
            }
          }}
        </Query>
      </ApolloProvider>
    );
  }
}

@withLocation
@withI18next(["common"])
@inject("appStore", "readerStore")
@observer
class ResourceSearchComp extends React.Component {
  constructor(props) {
    super(props);

    let defaultCondition = {
      id: 0,
      opFlag: true,
      del: true,
      op: "and",
      searchField: "all",
      searchInput: "",
    };

    let condition = [];

    let { search } = this.props;
    let params = { ...search };
    let { searchInput, searchField, op, mode = "simple" } = params;
    let searchTarget = params.searchTarget || "all";
    let advanceResourceType = params.searchTarget || "databases";
    let ispjournals = props.appStore.globalConfig["jumper.common.ispjournals"];
    let isEbooks = props.appStore.globalConfig["jumperrwd.common.isEbooks"];
    searchInput = searchInput || [];
    searchField = searchField || [];
    op = op || [];

    if (typeof searchInput === "string") {
      searchInput = [searchInput];
    }
    if (typeof searchField === "string") {
      searchField = [searchField];
    }
    if (typeof op === "string") {
      op = [op];
    }
    op.splice(0, 0, "");

    searchInput.forEach((input, key) => {
      input = input.trim();
      if (input !== "") {
        let id = condition.length + 1;
        let opFlag = true;
        let del = true;
        if (id === 1) {
          opFlag = false;
          del = false;
        }
        condition.push({
          ...defaultCondition,
          searchInput: input,
          searchField: searchField[key],
          op: op[key],
          id,
          opFlag,
          del,
        });
      }
    });

    if (condition.length === 0) {
      condition.push({
        ...defaultCondition,
        id: 1,
        op: "",
        opFlag: false,
        del: false,
      });
    }

    if (condition.length === 1) {
      condition.push({ ...defaultCondition, id: 2 });
    }

    this.state = {
      resourceType: searchTarget,
      advanceResourceType,
      searchInput: "",
      mode: mode,
      keywordCanempty: "0",
      recentSearchesDisplay: false,
      showPJournal: ispjournals === "1",
      showEbook: isEbooks === "1",
      defaultCondition: defaultCondition,
      condition: condition,
      seq: condition.length + 1,
      relatedField: null,
      displayFixed: "none",
      scrolldown_fix: "",
    };
    this.searchInput = React.createRef();
    this.searchInputFloat = React.createRef();
  }

  componentDidMount() {
    client.jumperrwdClient
      .query({
        query: getResourceRelatedField,
      })
      .then((res) => {
        if (res.data.getResourceRelatedField) {
          this.setState({ relatedField: res.data.getResourceRelatedField });
        }
      });

    if (this.state.mode === "simple") {
      let searchInput = "";
      let { search } = this.props;
      let params = { ...search };
      if (params.searchInput !== undefined) {
        searchInput = params.searchInput;
      }
      this.searchInput.current.val(searchInput);
      this.searchInputFloat.current.val(searchInput);
      this.setState({ searchInput: searchInput });
    }
    window.addEventListener("scroll", this.scrollTop);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollTop);
  }

  @autobind
  scrollTop() {
    if (window.pageYOffset >= 200) {
      this.setState({
        scrolldown_fix: "scrolldown_fix",
        displayFixed: "block",
      });
    } else {
      this.setState({ scrolldown_fix: "", displayFixed: "none" });
    }
  }

  @autobind
  handleSearchInputChange() {
    if (this.state.mode === "simple") {
      let searchInput = this.searchInput.current.val();

      if (this.state.displayFixed == "block") {
        searchInput = this.searchInputFloat.current.val();
        this.searchInput.current.val(searchInput);
      } else {
        this.searchInputFloat.current.val(searchInput);
      }
      this.setState({ searchInput: searchInput });
    }
  }

  @autobind
  autoComplete(text, e) {
    e.preventDefault();
    this.searchInput.current.val(text);
    this.setState({ searchInput: text });
  }

  @autobind
  handleResourceType(e) {
    e.preventDefault();
    this.setState({ resourceType: e.target.value });
  }

  @autobind
  handleAdvanceResourceType(e) {
    e.preventDefault();
    let condition = this.state.condition;
    condition.map((t) => {
      t.searchField = "all";
    });
    this.setState({
      advanceResourceType: e.target.value,
      condition: condition,
    });
  }

  @autobind
  handleAdvanceChange(item, e) {
    let condition = this.state.condition;
    condition.map((t, key) => {
      if (t.id === item.id) {
        condition[key][e.target.name] = e.target.value;
      }

      if (item.id === 1) {
        if (this.state.displayFixed == "block") {
          // searchInput = this.searchInputFloat.current.val();
          // this.searchInput.current.val(searchInput);
        } else {
          this.searchInputFloat.current.val(e.target.value);
        }
      }
    });

    this.setState({ condition: condition });
  }

  @autobind
  addCondition() {
    let condition = this.state.condition;
    condition.push({ ...this.state.defaultCondition, id: this.state.seq });
    this.setCondition({ condition: condition, seq: this.state.seq + 1 });
  }

  @autobind
  delCondition(item) {
    let id = -1;
    let condition = this.state.condition;
    condition.map((t, key) => {
      if (t.id === item.id) {
        id = key;
      }
    });
    condition.splice(id, 1);
    this.setCondition({ condition: condition, seq: this.state.seq });
  }

  @autobind
  setCondition(data) {
    const { condition, seq } = data;
    if (seq !== null) {
      this.setState({ condition: condition, seq: seq });
    } else {
      this.setState({ condition: condition });
    }
  }

  @autobind
  getResource(items) {
    let op = [];
    let searchField = [];
    let searchInput = [];
    items.map((item, key) => {
      searchField.push(item.searchField);
      searchInput.push(item.searchInput);
      if (key !== 0) {
        op.push(item.op);
      }
    });
    return {
      op,
      searchField,
      searchInput,
    };
  }

  @autobind
  onSubmit(e) {
    e.preventDefault();
    let { t } = this.props;
    let { resourceType } = this.state;
    let params = {};
    let searchInput = this.searchInput.current.val();
    let pageType = "search";
    let searchTarget;
    let sTag = "";

    if (this.state.displayFixed == "block") {
      searchInput = this.searchInputFloat.current.val();
      this.searchInput.current.val(searchInput);
    } else {
      this.searchInputFloat.current.val(searchInput);
    }

    if (this.state.keywordCanempty === "0" && searchInput === "") {
      alert(t("jumperrwd.common.pleaseEnterSearchword"));
      return;
    }
    if (resourceType !== "all") {
      searchTarget = resourceType;
      sTag = resourceType;
      params = { resourceType, searchTarget, searchInput, pageType };
    } else {
      params = { searchInput, pageType };
    }
    params.mode = "simple";

    client.jumperrwdClient
      .mutate({
        mutation: setResourceSearchHistory,
        variables: {
          form: {
            mode: "simple",
            searchInput: [searchInput],
            searchField: [],
            op: [],
            sTag,
          },
        },
      })
      .then((res) => {
        this.setState({ recentSearchesDisplay: false });
        navigate(["/resourceList", qs.stringify(params)].join("?"));
      });
  }

  @autobind
  onFloatSubmit(e) {
    e.preventDefault();
    let { t } = this.props;
    let { resourceType } = this.state;
    let params = {};
    let searchInput = this.searchInputFloat.current.val();
    let pageType = "search";
    let searchTarget;
    let sTag = "";

    if (this.state.keywordCanempty === "0" && searchInput === "") {
      alert(t("jumperrwd.common.pleaseEnterSearchword"));
      return;
    }
    if (resourceType !== "all") {
      searchTarget = resourceType;
      sTag = resourceType;
      params = { resourceType, searchTarget, searchInput, pageType };
    } else {
      params = { searchInput, pageType };
    }
    params.mode = "simple";

    client.jumperrwdClient
      .mutate({
        mutation: setResourceSearchHistory,
        variables: {
          form: {
            mode: "simple",
            searchInput: [searchInput],
            searchField: [],
            op: [],
            sTag,
          },
        },
      })
      .then((res) => {
        this.setState(
          {
            recentSearchesDisplay: false,
            mode: "simple",
            searchInput: searchInput,
          },
          () => {
            window.scrollTo(0, 0);
            navigate(["/resourceList", qs.stringify(params)].join("?"));
          }
        );
      });
  }

  @autobind
  onAdvanceSubmit(e) {
    e.preventDefault();
    let { t } = this.props;
    let { advanceResourceType, condition } = this.state;
    let pageType = "search";
    let searchTarget = advanceResourceType;
    let params = searchAPI.searchParamfix(this.getResource(condition));

    if (this.state.keywordCanempty === "0" && params.searchInput.length === 0) {
      alert(t("jumperrwd.common.pleaseEnterSearchword"));
      return;
    }

    params = {
      ...params,
      resourceType: advanceResourceType,
      pageType,
      searchTarget,
      mode: "advance",
    };

    let sTag = advanceResourceType;
    client.jumperrwdClient
      .mutate({
        mutation: setResourceSearchHistory,
        variables: {
          form: {
            mode: "complex",
            searchField: params.searchField,
            searchInput: params.searchInput,
            op: params.op,
            sTag,
          },
        },
      })
      .then((res) => {
        navigate(["/resourceList", qs.stringify(params)].join("?"));
      });
  }

  @autobind
  changeSearchType(type, e) {
    e.preventDefault();
    let { mode } = this.state;
    /*let params = qs.parseUrl(window.location.href).query;
    let { searchInput, searchField, op, mode = "simple" } = params;
    params.mode = mode == "simple" ? "advance":"simple";
    delete params.pid;
    delete params.filterPid;*/
    mode = mode === "simple" ? "advance" : "simple";
    this.searchInputFloat.current.val("");
    this.setState(
      {
        mode: mode,
      },
      () => {
        //navigate([location.pathname, qs.stringify(params)].join("?"));
      }
    );
  }

  @autobind
  submitKeyword(keyword, e) {
    e.preventDefault();
    this.searchInput.current.val(keyword);
    this.searchForm.dispatchEvent(new Event("submit"));
  }

  @autobind
  setRecentSearch(rshItem, e) {
    e.preventDefault();
    if (rshItem.searchField && rshItem.searchField.length > 0) {
      let condition = [];
      let { searchField, searchValue, op, searchType } = rshItem;
      searchValue.forEach((value, key) => {
        value = value.trim();
        if (value !== "NULL") {
          let id = condition.length + 1;
          let opFlag = true;
          let del = true;
          if (id === 1) {
            opFlag = false;
            del = false;
          }
          condition.push({
            ...this.state.defaultCondition,
            searchInput: value,
            searchField: searchField[key],
            op: op[key],
            id,
            opFlag,
            del,
          });
        }
      });
      let seq = condition.length + 1;
      this.setState({
        mode: "advance",
        advanceResourceType: searchType,
        condition,
        seq,
      });
    } else {
      let searchValue = rshItem.searchValue[0];
      let searchType = rshItem.searchType;
      this.searchInput.current.val(searchValue);
      this.setState({
        mode: "simple",
        resourceType: searchType,
        searchInput: searchValue,
      });
    }
  }

  render() {
    let { t, appStore } = this.props;
    let { searchBlock } = appStore;
    let {
      showPJournal,
      showEbook,
      mode,
      relatedField,
      advanceResourceType,
      recentSearchesDisplay,
    } = this.state;
    return (
      <>
        <div
          className={`tab-content ${this.state.scrolldown_fix}`}
          id="resource"
          style={{ display: this.state.displayFixed }}>
          <form onSubmit={this.onFloatSubmit}>
            <div className="form_grp form_inline">
              <select
                id="selectSample"
                name=""
                title="資源類型"
                value={this.state.resourceType}
                onChange={this.handleResourceType}>
                {mode === "simple" && (
                  <option value="all">{t("jumper.common.all")}</option>
                )}
                <option value="databases">
                  {t("jumper.common.leftmenu.databases")}
                </option>
                <option value="journal">
                  {t("jumper.common.leftmenu.ejournals")}
                </option>
                {showPJournal && (
                  <option value="paper_journal">
                    {t("jumper.common.leftmenu.pjournals")}
                  </option>
                )}
                {showEbook && (
                  <option value="ebook">
                    {t("jumper.common.leftmenu.ebooks")}
                  </option>
                )}
              </select>
              <KeyboardInput
                ref={this.searchInputFloat}
                placeholder={t("jumper.common.keywordfield.resource.tips")}
                onChange={this.handleSearchInputChange}
              />
              <div className="btn_grp form_inline">
                <input
                  name=""
                  type="submit"
                  title={t("jumper.common.search")}
                  value={t("jumper.common.search")}
                />
              </div>
            </div>
          </form>
        </div>
        <div className="tab-content" id="resource">
          {mode === "simple" && (
            <>
              <form ref={(c) => (this.searchForm = c)} onSubmit={this.onSubmit}>
                <div className="form_grp form_inline">
                  <select
                    id="selectSample"
                    name=""
                    title="資源類型"
                    value={this.state.resourceType}
                    onChange={this.handleResourceType}>
                    <option value="all">{t("jumper.common.all")}</option>
                    <option value="databases">
                      {t("jumper.common.leftmenu.databases")}
                    </option>
                    <option value="journal">
                      {t("jumper.common.leftmenu.ejournals")}
                    </option>
                    {showPJournal && (
                      <option value="paper_journal">
                        {t("jumper.common.leftmenu.pjournals")}
                      </option>
                    )}
                    {showEbook && (
                      <option value="ebook">
                        {t("jumper.common.leftmenu.ebooks")}
                      </option>
                    )}
                  </select>
                  <KeyboardInput
                    ref={this.searchInput}
                    value={this.state.searchInput}
                    placeholder={t("jumper.common.keywordfield.resource.tips")}
                    onChange={this.handleSearchInputChange}
                    onFocus={() => {
                      this.setState({ recentSearchesDisplay: true });
                    }}
                    onBlur={() => {
                      setTimeout(
                        () => this.setState({ recentSearchesDisplay: false }),
                        200
                      );
                    }}
                  />
                  <input
                    name=""
                    type="submit"
                    title={t("jumper.common.search")}
                    value={t("jumper.common.search")}
                  />
                  <a
                    tabIndex="0"
                    className="advanced_search"
                    onClick={this.changeSearchType.bind(this, "advance")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.changeSearchType.bind(this, "advance");
                      }
                    }}>
                    {t("jumper.common.advsearch")}
                  </a>
                  {recentSearchesDisplay && (
                    <div className="recent_searches">
                      <PromptWord
                        q={this.state.searchInput}
                        resourceType={this.state.resourceType}
                        autoComplete={this.autoComplete}
                      />
                      <RecentSearch
                        readerStore={this.props.readerStore}
                        t={this.props.t}
                        setRecentSearch={this.setRecentSearch}
                      />
                    </div>
                  )}
                </div>
                <Link href="/resourceList" className="link">
                  {t("jumperrwd.search.resourceView")}
                </Link>
              </form>
              {searchBlock !== null && searchBlock.keywordTypeList && (
                <>
                  {searchBlock.keywordTypeList.map((keywordTypeItem) => {
                    if (
                      keywordTypeItem.type === "resource" &&
                      keywordTypeItem.keywordList
                    ) {
                      return (
                        <div className="keywordHot">
                          <ul>
                            <b>{t("jumperrwd.common.hotKeyword")}：</b>
                            {keywordTypeItem.keywordList.map((keyword) => {
                              return (
                                <li>
                                  <a
                                    tabIndex="0"
                                    onClick={this.submitKeyword.bind(
                                      this,
                                      keyword.name
                                    )}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        this.submitKeyword(
                                          keyword.name,
                                          e
                                        );
                                      }
                                    }}>
                                    {keyword.name}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      );
                    }
                    return "";
                  })}
                </>
              )}
            </>
          )}
          {mode === "advance" && (
            <form onSubmit={this.onAdvanceSubmit}>
              {this.state.condition.map((item, key) => {
                return (
                  <>
                    <div
                      className={
                        item.id === 1
                          ? "form_grp form_inline"
                          : "form_grp form_inline condition"
                      }>
                      {item.id === 1 && (
                        <select
                          className="select_connected"
                          title="資源類型"
                          value={this.state.advanceResourceType}
                          onChange={this.handleAdvanceResourceType}>
                          <option value="databases">
                            {t("jumper.common.leftmenu.databases")}
                          </option>
                          <option value="journal">
                            {t("jumper.common.leftmenu.ejournals")}
                          </option>
                          {showPJournal && (
                            <option value="paper_journal">
                              {t("jumper.common.leftmenu.pjournals")}
                            </option>
                          )}
                          {showEbook && (
                            <option value="ebook">
                              {t("jumper.common.leftmenu.ebooks")}
                            </option>
                          )}
                        </select>
                      )}
                      {item.opFlag && (
                        <select
                          name="op"
                          title="邏輯條件"
                          value={item.op}
                          onChange={this.handleAdvanceChange.bind(this, item)}>
                          <option value="and">AND</option>
                          <option value="or">OR</option>
                          <option value="not">NOT</option>
                        </select>
                      )}
                      <KeyboardInput
                        name={"searchInput"}
                        onChange={this.handleAdvanceChange.bind(this, item)}
                        value={item.searchInput || ""}
                      />
                      <select
                        name="searchField"
                        title="篩選條件"
                        value={item.searchField}
                        onChange={this.handleAdvanceChange.bind(this, item)}>
                        <option value="all">{t("jumper.common.all")}</option>
                        {relatedField &&
                          ((advanceResourceType === "databases" &&
                            relatedField.dbFieldList &&
                            relatedField.dbFieldList.map((dbField) => {
                              return (
                                <option value={dbField}>
                                  {t(
                                    "jumperrwd.eb_basic_extension.name." +
                                      dbField
                                  )}
                                </option>
                              );
                            })) ||
                            ((advanceResourceType === "journal" ||
                              advanceResourceType === "paper_journal" ||
                              advanceResourceType === "ebook") &&
                              relatedField.journalFieldList &&
                              relatedField.journalFieldList.map(
                                (journalField) => {
                                  let nameField = "journalName";
                                  if (advanceResourceType === "ebook") {
                                    nameField = "ebookName";
                                  }
                                  return (
                                    <option value={journalField}>
                                      {t(
                                        "jumperrwd.eb_journal_extension." +
                                          nameField +
                                          "." +
                                          journalField
                                      )}
                                    </option>
                                  );
                                }
                              )))}
                      </select>
                      {item.del && (
                        <button
                          type="button"
                          tabIndex="0"
                          className="delete"
                          onClick={() => {
                            this.delCondition(item);
                          }}>
                          {t("jumperrwd.common.delete")}
                        </button>
                      )}
                      {item.id === 1 && (
                        <>
                          <div className="btn_grp form_inline">
                            <input
                              type="submit"
                              title={t("jumper.common.search")}
                              value={t("jumper.common.search")}
                            />
                            <a
                              tabIndex="0"
                              className="simple_search"
                              onClick={this.changeSearchType.bind(
                                this,
                                "simple"
                              )}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  this.changeSearchType.bind(this, "simple");
                                }
                              }}>
                              {t("jumper.common.simplesearch")}
                            </a>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                );
              })}
              <button
                type="button"
                tabIndex="0"
                className="add_condition"
                onClick={this.addCondition}>
                {t("jumperrwd.search.addConditionLine")}
              </button>
              <br />
              <Link href="/resourceList" className="link">
                {t("jumperrwd.search.resourceView")}
              </Link>
            </form>
          )}
        </div>
      </>
    );
  }
}

ResourceSearchComp.defaultProps = {};

ResourceSearchComp.propTypes = {
  t: PropTypes.func,
};

export default ResourceSearchComp;
