import React from "react";
import { autobind } from "react-decoration";
import withLocation from "lib/withLocation";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { withI18next } from "lib/withI18next";
import IntegrationSearchComp from "./IntegrationSearchComp";
import ResourceSearchComp from "./ResourceSearchComp";
import CustomIntegrationSearchComp from "./CustomIntegrationSearchComp";
import { navigate } from "gatsby";
import qs from "query-string";

@withLocation
@withI18next(["common"])
@inject("appStore")
@observer
class SearchBlock extends React.Component {
  constructor(props) {
    super(props);

    let { globalConfig } = props.appStore;
    let showHold = globalConfig["jumper.common.iscatalog"] === "1";
    let showIntegration =
      globalConfig["jumper.common.isdiscovery"] === "1" ||
      globalConfig["jumper.common.ishyint"] === "1";
    let showResource = globalConfig["jumper.common.isresource"] === "1";
    let tabbar = globalConfig["jumper.common.tabbar.default"];
    if (tabbar === "collections") {
      tabbar = "hold";
    } else if (tabbar === "fullsearch") {
      tabbar = "integration";
    }
    let searchType = this.props.searchType || tabbar;
    this.state = {
      searchType,
      showHold,
      showIntegration,
      showResource,
    };
  }

  componentWillReceiveProps(props) {
    if (
      props.search.searchBlockType !== undefined &&
      this.props.search.searchBlockType !== props.search.searchBlockType
    ) {
      this.setState({ searchType: props.search.searchBlockType });
    }
  }

  @autobind
  changeSearchType(searchType, e) {
    e.preventDefault();
    let { search, location, appStore } = this.props;
    let { globalConfig } = appStore;
    let { pathname } = location;
    if (searchType === "resource") {
      if (!pathname.includes("/resourceList")) {
        if (globalConfig["jumper.common.resourcetabmode"] === "1") {
          navigate("/resourceList");
        } else if (search.searchBlockType === searchType || search.searchBlockType === undefined) {
          this.setState({ searchType: searchType });
        } else {
          let params = {};
          params.searchBlockType = searchType;
          navigate(["/", qs.stringify(params)].join("?"));
        }
      } else {
        this.setState({ searchType: searchType });
      }
    } else {
      if (pathname === "/") {
        if (search.searchBlockType === searchType || search.searchBlockType === undefined) {
          this.setState({ searchType: searchType });
        } else {
          let params = {};
          params.searchBlockType = searchType;
          navigate(["/", qs.stringify(params)].join("?"));
        }
      } else if (pathname.includes("/searchResult")) {
        if (searchType !== search.searchBlockType) {
          let params = {};
          params.searchBlockType = searchType;
          navigate(["/", qs.stringify(params)].join("?"));
        }
      } else {
        let params = {};
        params.searchBlockType = searchType;
        navigate(["/", qs.stringify(params)].join("?"));
      }
    }
  }

  @autobind
  searchComp() {
    let { appStore } = this.props;
    let { searchBlock } = appStore;
    switch (this.state.searchType) {
      case "integration":
        return <IntegrationSearchComp />;
      case "resource":
        return <ResourceSearchComp resourceType={this.props.resourceType} />;
      default:
        if (searchBlock && searchBlock.searchTypeList) {
          for (let i = 0; i < searchBlock.searchTypeList.length; i++) {
            let searchTypeItem = searchBlock.searchTypeList[i];
            if (
              searchTypeItem.type === this.state.searchType &&
              searchTypeItem.mode === "integration"
            ) {
              return (
                <CustomIntegrationSearchComp searchTypeItem={searchTypeItem} />
              );
            }
          }
        }
    }
  }

  render() {
    let { appStore } = this.props;
    let { showHold, showIntegration, showResource } = this.state;
    let { searchBlock } = appStore;
    return (
      <>
        <section className="col-12 search_block">
          <img className="header_bg" src="/file/images/header_bg.png" alt="搜尋框背景" />
          <div className="container">
            <div className="col">
              <div className="search_system">
                <div className="tabSet">
                  <ul className="tabs">
                    {searchBlock &&
                      searchBlock.searchTypeList &&
                      searchBlock.searchTypeList.map((searchTypeItem) => {
                        if (searchTypeItem.type === "integration") {
                          return (
                            <>
                              {showIntegration && (
                                <li
                                  className={
                                    this.state.searchType === "integration"
                                      ? "active"
                                      : ""
                                  }>
                                  <a
                                    tabIndex="0"
                                    onClick={this.changeSearchType.bind(
                                      this,
                                      "integration"
                                    )}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        this.changeSearchType("integration", e);
                                      }
                                    }}>
                                    {this.props.t(
                                      "jumperrwd.common.fulltextSearch"
                                    )}
                                  </a>
                                </li>
                              )}
                            </>
                          );
                        } else if (searchTypeItem.type === "resource") {
                          return (
                            <>
                              {showResource && (
                                <li
                                  className={
                                    this.state.searchType === "resource"
                                      ? "active"
                                      : ""
                                  }>
                                  <a
                                    tabIndex="0"
                                    onClick={this.changeSearchType.bind(
                                      this,
                                      "resource"
                                    )}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        this.changeSearchType("resource", e);
                                      }
                                    }}>
                                    {this.props.t("jumper.common.dbjournal")}
                                  </a>
                                </li>
                              )}
                            </>
                          );
                        } else if (searchTypeItem.type !== "hold" || showHold) {
                          return (
                            <>
                              <li
                                className={
                                  this.state.searchType === searchTypeItem.type
                                    ? "active"
                                    : ""
                                }>
                                <a
                                  tabIndex="0"
                                  onClick={this.changeSearchType.bind(
                                    this,
                                    searchTypeItem.type
                                  )}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      this.changeSearchType(
                                        searchTypeItem.type,
                                        e
                                      );
                                    }
                                  }}>
                                  {searchTypeItem.message
                                    ? this.props.t(searchTypeItem.message)
                                    : searchTypeItem.name}
                                </a>
                              </li>
                            </>
                          );
                        }
                        return "";
                      })}
                  </ul>
                </div>
                {this.searchComp()}
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

SearchBlock.defaultProps = {};

SearchBlock.propTypes = {
  t: PropTypes.func,
};

export default SearchBlock;
