import React from "react";
import { autobind } from "react-decoration";
import { navigate } from "gatsby";
import withLocation from "lib/withLocation";
import qs from "query-string";
import PropTypes from "prop-types";
import client from "lib/ApolloClient";
import { inject, observer } from "mobx-react";
import { withI18next } from "lib/withI18next";
import gql from "graphql-tag";
import KeyboardInput from "components/form/KeyboardInput";
import Link from "lib/Link";
import { ApolloProvider, Query } from "react-apollo";

const getResourceAutoComplete = gql`
  query getResourceAutoComplete($form: AutoCompleteForm) {
    getResourceAutoComplete(Input: $form) {
      list
    }
  }
`;

class PromptWord extends React.Component {
  render() {
    let { q, resourceType } = this.props;
    if (q == null || q.trim() == "") {
      return null;
    }

    return (
      <ApolloProvider client={client.jumperrwdClient}>
        <Query
          query={getResourceAutoComplete}
          variables={{ form: { resourceType: resourceType, inputText: q } }}>
          {({ loading, data, error }) => {
            if (error) return "";
            if (!loading && data !== null) {
              const { list } = data.getResourceAutoComplete;
              if (list == null || list.length == 0) {
                return null;
              }
              return (
                <>
                  <ul className="promptword">
                    {list.map((word, key) => (
                      <li key={`word${key}`}>
                        <a
                          tabIndex="0"
                          onClick={this.props.autoComplete.bind(this, word)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              this.props.autoComplete(word, e);
                            }
                          }}>
                          {word}
                        </a>
                      </li>
                    ))}
                  </ul>
                </>
              );
            } else {
              return null;
            }
          }}
        </Query>
      </ApolloProvider>
    );
  }
}

PromptWord.propTypes = {
  q: PropTypes.string,
};

@inject("appStore")
@observer
@withI18next(["common"])
class CustomIntegrationSearchComp extends React.Component {
  constructor(props) {
    super(props);

    let conditions = [];
    let params = qs.parseUrl(window.location.href).query;
    let { searchInput, searchField, op, mode = "simple" } = params;

    searchInput = searchInput || [];
    searchField = searchField || [];
    op = op || [];

    if (typeof searchInput === "string") {
      searchInput = [searchInput];
    }
    if (typeof searchField === "string") {
      searchField = [searchField];
    }
    if (typeof op === "string") {
      op = [op];
    }
    op.splice(0, 0, "");

    searchInput.forEach((input, key) => {
      input = input.trim();
      let field = searchField[key];
      if (input != "") {
        conditions.push({
          input,
          field: searchField[key],
          op: op[key],
        });
      }
    });

    if (conditions.length == 0) {
      conditions.push({ input: "", field: "TI", op: "" });
    }

    if (conditions.length === 1) {
      conditions.push({ input: "", field: "TI", op: "and" });
    }

    this.state = {
      conditions,
      mode,
      searchInput: searchInput[0],
      displayFixed: "none",
      scrolldown_fix: "",
      recentSearchesDisplay: false,
    };
    this.searchInput = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollTop);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollTop);
  }

  @autobind
  scrollTop() {
    if (window.pageYOffset >= 200) {
      this.setState({
        scrolldown_fix: "scrolldown_fix",
        displayFixed: "block",
      });
    } else {
      this.setState({ scrolldown_fix: "", displayFixed: "none" });
    }
  }

  @autobind
  onSubmit(e) {
    e.preventDefault();
    const { t } = this.props;
    let db = this.props.searchTypeItem.hyintID ? this.props.searchTypeItem.hyintID.split(",") : [];
    let decentralized = "Y";
    let centralized = "N";
    let { conditions, mode } = this.state;
    let maxCondition = 3;
    let searchBlockType = this.props.searchTypeItem.type;

    if (mode == "simple") {
      maxCondition = 1;
    }

    let searchInput = [],
      searchField = [],
      op = [];

    conditions.forEach((condition, key) => {
      if (key < maxCondition) {
        const { input, field, op: operator } = condition;
        if (input.trim() != "") {
          searchInput.push(input);
          searchField.push(field);
          if (operator != "") {
            op.push(operator);
          }
        }
      }
    });

    let params = { searchInput, searchField, op, db, mode, searchBlockType, centralized, decentralized };

    if (searchInput.length == 0) {
      alert(t`message.entersearchword`);
    } else {
      navigate(["/searchResult", qs.stringify(params)].join("?"));
    }

    this.setState({
      recentSearchesDisplay: false,
    });
  }

  @autobind
  handlerSearchInputChange(key, e) {
    e.persist();
    const { name, value } = e.target;
    let { conditions } = this.state;
    conditions[key][name] = value;
    this.setState({ conditions });
  }

  toggleSearchMode = () => {
    let { mode } = this.state; //
    /*let params = qs.parseUrl(window.location.href).query;
    let { searchInput, searchField, op, mode = "simple" } = params;
    params.mode = mode == "simple" ? "advance":"simple";
    delete params.pid;
    delete params.filterPid;*/ mode =
      mode == "simple" ? "advance" : "simple";
    this.setState(
      {
        mode,
      },
      () => {
        //navigate([window.location.uri, qs.stringify(params)].join("?"));
      }
    );
  };

  addContition = () => {
    let { conditions, centralized, decentralized, mode } = this.state;
    let maxCondition = decentralized ? 3 : 12;
    if (mode == "simple") {
      maxCondition = 1;
    }
    if (conditions.length < maxCondition) {
      conditions.push({ input: "", field: "TI", op: "and" });
      this.setState({ conditions });
    }
  };

  delCondition = (index) => {
    let { conditions } = this.state;
    conditions.splice([index], 1);
    this.setState({ conditions });
  };

  @autobind
  submitKeyword(keyword, e) {
    e.preventDefault();
    let conditions = { ...this.state.conditions };
    conditions[0].input = keyword;
    this.setState(conditions);
    //this.searchInput.current.val(keyword);
    this.searchForm.dispatchEvent(new Event("submit"));
  }

  @autobind
  autoComplete(text, e) {
    e.preventDefault();
    let { conditions } = this.state;
    conditions[0]["input"] = text;
    this.setState({ conditions });
  }

  render() {
    let { t, appStore } = this.props;
    let { searchBlock } = appStore;
    const { conditions, mode, recentSearchesDisplay } = this.state;

    let isSimple = mode == "simple";

    let maxCondition = 3;
    if (mode === "simple") {
      maxCondition = 1;
    }
    let showAdd = conditions.length < maxCondition;

    let hintMessage = t("jumper.common.keywordfield.fullsearch.tips");
    if (this.props.searchTypeItem.hintMessage) {
      hintMessage = t(this.props.searchTypeItem.hintMessage);
    }
    return (
      <>
        <div
          className={`tab-content ${this.state.scrolldown_fix}`}
          id="intergration"
          style={{ display: this.state.displayFixed }}>
          <form onSubmit={this.onSubmit}>
            {conditions.map((condition, key) => {
              let isFirst = key == 0;
              let formLineClass = isFirst ? "" : "condition";
              const { field, input, op } = condition;
              if (mode == "simple" && key > 0) {
                return null;
              }
              if (!showAdd && key > maxCondition) {
                return null;
              }
              return (
                <div className={`form_grp form_inline ${formLineClass}`}>
                  <KeyboardInput
                    placeholder={hintMessage}
                    title={hintMessage}
                    value={input}
                    name="input"
                    onChange={this.handlerSearchInputChange.bind(this, key)}
                  />
                  <input
                    type="submit"
                    title={t("jumper.common.search")}
                    value={t("jumper.common.search")}
                  />
                </div>
              );
            })}
          </form>
        </div>
        <div className="tab-content" id="intergration">
          <form ref={(c) => (this.searchForm = c)} onSubmit={this.onSubmit}>
            {conditions.map((condition, key) => {
              let isFirst = key == 0;
              let formLineClass = isFirst ? "" : "condition";
              const { field, input, op } = condition;
              if (mode == "simple" && key > 0) {
                return null;
              }
              if (!showAdd && key > maxCondition) {
                return null;
              }
              return (
                <div className={`form_grp form_inline ${formLineClass}`}>
                  {!isFirst && (
                    <select
                      name="op"
                      title="邏輯條件"
                      value={op}
                      onChange={this.handlerSearchInputChange.bind(this, key)}>
                      <option value="and">AND</option>
                      <option value="or">OR</option>
                      <option value="not">NOT</option>
                    </select>
                  )}
                  <KeyboardInput
                    placeholder={hintMessage}
                    title={hintMessage}
                    value={input}
                    name="input"
                    onChange={this.handlerSearchInputChange.bind(this, key)}
                    onFocus={() => {
                      this.setState({ recentSearchesDisplay: true });
                    }}
                    onBlur={() => {
                      setTimeout(
                        () => this.setState({ recentSearchesDisplay: false }),
                        200
                      );
                    }}
                    autoComplete={isSimple ? "off" : "on"}
                  />
                  {isSimple && recentSearchesDisplay && (
                    <div className="recent_searches">
                      <PromptWord
                        q={input}
                        autoComplete={this.autoComplete}
                      />
                    </div>
                  )}
                  {!isSimple && (
                    <select
                      name="field"
                      title="篩選條件"
                      onChange={this.handlerSearchInputChange.bind(this, key)}
                      value={field}>
                      <option value="TI">
                        {t("hyint.common.table.title")}
                      </option>
                      <option value="AU">
                        {t("hyint.common.table.author")}
                      </option>
                      <option value="SUBJECT">
                        {t("hyint.common.table.subject")}
                      </option>
                      <option value="PUB">
                        {t("hyint.common.table.publisher")}
                      </option>
                      <option value="ISSN">
                        {t("hyint.common.table.issn")}
                      </option>
                      <option value="ISBN">
                        {t("hyint.common.table.isbn")}
                      </option>
                      <option value="KW">{t("hyint.common.table.kw")}</option>
                    </select>
                  )}
                  {!isFirst && (
                    <button
                      type="button"
                      tabIndex="0"
                      className="delete"
                      onClick={this.delCondition.bind(this, key)}>
                      delete
                    </button>
                  )}
                  {isFirst && (
                    <>
                      <input
                        type="submit"
                        title={t("jumper.common.search")}
                        value={t("jumper.common.search")}
                      />
                      <a
                        tabIndex="0"
                        className="advanced_search"
                        onClick={this.toggleSearchMode}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.toggleSearchMode(e);
                          }
                        }}>
                        {mode == "simple"
                          ? t("jumper.common.advsearch")
                          : t("jumper.common.simplesearch")}
                      </a>
                    </>
                  )}
                </div>
              );
            })}
            {!isSimple && showAdd && (
              <button
                type="button"
                tabIndex="0"
                className="add_condition"
                onClick={this.addContition}>
                {t("jumperrwd.search.addConditionLine")}
              </button>
            )}
          </form>
          {searchBlock !== null && searchBlock.keywordTypeList && (
            <>
              {searchBlock.keywordTypeList.map((keywordTypeItem) => {
                if (
                  keywordTypeItem.type === "integration" &&
                  keywordTypeItem.keywordList
                ) {
                  return (
                    <div className="keywordHot">
                      <ul>
                        <b>{t("jumperrwd.common.hotKeyword")}：</b>
                        {keywordTypeItem.keywordList.map((keyword) => {
                          return (
                            <li>
                              <a
                                tabIndex="0"
                                onClick={this.submitKeyword.bind(
                                  this,
                                  keyword.name
                                )}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    this.submitKeyword(
                                      keyword.name,
                                      e
                                    );
                                  }
                                }}>
                                {keyword.name}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                }
                return "";
              })}
            </>
          )}
        </div>
      </>
    );
  }
}

CustomIntegrationSearchComp.defaultProps = {};

CustomIntegrationSearchComp.propTypes = {
  t: PropTypes.func,
};
export default CustomIntegrationSearchComp;
